import { createGlobalStyle } from 'styled-components'
import { up } from 'src/utils/media'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SF UI';
    src: url(${require('src/public/fonts/sf-ui-bold.eot')});
    src: url(${require('src/public/fonts/sf-ui-bold.eot?#iefix')}) format('embedded-opentype'),
      url(${require('src/public/fonts/sf-ui-bold.ttf')}) format('truetype'),
      url(${require('src/public/fonts/sf-ui-bold.woff2')}) format('woff2'),
      url(${require('src/public/fonts/sf-ui-bold.woff')}) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF UI';
    src: url(${require('src/public/fonts/sf-ui-semibold.eot')});
    src: url(${require('src/public/fonts/sf-ui-semibold.eot?#iefix')}) format('embedded-opentype'),
      url(${require('src/public/fonts/sf-ui-semibold.ttf')}) format('truetype'),
      url(${require('src/public/fonts/sf-ui-semibold.woff2')}) format('woff2'),
      url(${require('src/public/fonts/sf-ui-semibold.woff')}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF UI';
    src: url(${require('src/public/fonts/sf-ui-medium.eot')});
    src: url(${require('src/public/fonts/sf-ui-medium.eot?#iefix')}) format('embedded-opentype'),
      url(${require('src/public/fonts/sf-ui-medium.ttf')}) format('truetype'),
      url(${require('src/public/fonts/sf-ui-medium.woff2')}) format('woff2'),
      url(${require('src/public/fonts/sf-ui-medium.woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fontFamily}, Arial, Helvetica, sans-serif;
  }

  html {
    font-size: 62.5%;
    font-family: ${props => props.theme.fontFamily}, Arial, Helvetica, sans-serif;
    color: ${props => props.theme.colors.bodyText};
    scroll-behavior: smooth;
  }

  body {
    background: ${props => props.theme.colors.background};
    font-family: ${props => props.theme.fontFamily};
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.theme.colors.bodyText};

    /* Custom Scrollbar For Internet Explorer */

    scrollbar-face-color: ${props => props.theme.colors.path};
    scrollbar-track-color: ${props => props.theme.colors.shadow};

    ${up('largeDesktop')} {
      font-size: 3.2rem;
    }

    &.fixed {
      overflow: hidden;
    }
  }

  main {
    display: flex;
  }

  p, span {
    font-family: inherit;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.bodyText};
    line-height: 1.5;
    letter-spacing: 0.01em;

    ${up('largeDesktop')} {
      font-size: 2.8rem;
    }
  }

  a {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none;
    text-decoration: none;

    &:hover, &:active {
      color: inherit;
    }
  }

  .mobile {
    ${up('tablet')} {
      display: none !important;
    }
  }

  .desktop {
    display: none !important;

    ${up('tablet')} {
      display: block !important;
    }
  }


  /* ===== CUSTOM SCROLLBAR ===== */

  /* For Google Chrome */
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.path};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.shadow};
  }

  /* ==== END OF SCROLLBAR STYLING ===== */

  @keyframes fadeIn {
    0% {
      opacity: 0;
      max-width: 0;
      max-height: 0;
    }

    100% {
      opacity: 1;
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  @keyframes floatingSmall {
    0% {
      /* transform: translate3d(0, 0, 0); */
      transform: translateZ(0);
    }

    65% {
      transform: translate3d(0, 5px, 0);
      /* transform: translateY(5px); */
    }
    
    100% {
      /* transform: translate3d(0, 0, 0); */
      transform: translateZ(0);
    }
  }

  @keyframes floatingLarge {
    0% {
      /* transform: translate3d(0, 0, 0); */
      transform: translateZ(0);
    }

    65% {
      transform: translate3d(0, 10px, 0);
      /* transform: translateY(10px); */
    }

    100% {
      /* transform: translate3d(0, 0, 0); */
      /* transform: translateY(0); */
      transform: translateZ(0);
    }
  }
`

export default GlobalStyle
