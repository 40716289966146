import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  containerWidth: {
    sm: 'calc(100vw - 4rem)',
    md: '50vw',
    lg: 'calc(100vw - 10rem)',
    xl: '80vw'
  },
  fontFamily: 'SF UI',
  colors: {
    background: '#FFFFFF',
    bodyText: '#302F2F',
    primary: '#3C73EF',
    primaryLight: '#6795fe',
    secondary: {
      500: '#90A1B5',
      200: '#A6B6C9'
    },
    downloadLink: '#74879F',
    path: '#BED4EE',
    shadow: 'rgba(79,98,245,.16)',
    border: '#B8D0F3',
    placeRed: '#F74E4F',
    placeBlue: '#3C73EF',
    placeGreen: '#04D0B0'
  },
  columnGutter: {
    lg: '20'
  }
}

export default theme
